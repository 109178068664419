.navbarLogin {
	display: flex;
	width: 100%;
	height: 17vh;
	background-color: #2d77c0;
	justify-content: center;
	/* margin: 2.5vh 0; */
}

.navbarLogin > img {
	height: 100%;
}
