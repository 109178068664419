.flipping-card2 {
    perspective: 1000px;
    cursor: pointer;
    position: relative;
  }
  
  .front2,
  .back2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transition: transform 0.6s;
  }

  
  .front2 {
    z-index: 2;
    transform: rotateY(0);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  
  .back2 {
    transform: rotateY(180deg);
    background-color: #f5f5f5;
    color: #333;
    overflow: scroll;
    display: flex;
    align-items: center;
  }
  
  .flipping-card2.flipped2 .front2 {
    transform: rotateY(180deg);
    z-index: 1;
  }
  
  .flipping-card2.flipped2 .back2 {
    transform: rotateY(0);
    z-index: 2;
  }
  
  .front2 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }
  
  .front2 .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    z-index: 1;
  }
  
  .front2 .content {
    position: relative;
    z-index: 2;
    padding: 10%;
    color: white;
  }

  .textBack2{

    font-family: "Josefin"!important;
      font-size: 16px !important;
    color: #5c5c5c !important;
      font-weight: 400 !important;
      font-style: italic !important;
  }
  