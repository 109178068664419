* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	color: var(--text-color-claro);
}

#root {
	margin: 0;
	padding: 0;
	bottom: 0;
	background-color: var(--color-claro);
}

:root {
	--azul-principe:#215AA6;
	--celeste:#B3C9E6;
	--claro:#E0CAB7;
	--primary-blue:	#0077B6;
	--light-blue:#00B4D8;
	--gray-text:#5c5c5c;
	--ultra-light-blue:#EFFCFF;
	--color-oscuro: #0077B6;
	--color-footer: #1e252b5e;
	--color-fondo-oscuro: #282828;
	--color-verde: #64a620;
	--color-verde-footer: rgba(32, 86, 63, 0.17);
	--color-gris: #8a8a8a;
	--color-gris-trans: #8a8a8a94;
	--color-claro: #eaede2;
	--color-rosa-super-clarito: #e9ced2;
	--color-rosa-claro-trans: #f4c6c950;
	--text-color: #fff;
	--text-color-claro: #565862;
	--text-color-navbar: #fff;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
