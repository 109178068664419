/* Estilos para el contenedor de cada item del carrusel */
.carousel-item {
	position: relative;
	display: inline-block; /* Asegúrate de que el contenedor se ajuste al tamaño de la imagen */
}
.react-multiple-carousel__arrow {
	z-index: 0;
}
/* Estilos para la imagen dentro del carrusel */
.carousel-item img {
	display: block;
	max-width: 100%; /* Asegúrate de que la imagen no desborde el contenedor */
}

/* Estilos para el contenedor del ícono de lápiz */
.edit-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	position: absolute;
	bottom: 33%; /* Puedes ajustar la posición vertical aquí */
	right: 5vw; /* Puedes ajustar la posición horizontal aquí */
	background-color: var(--color-verde-claro);

	color: #ffffff; /* Color del ícono */
	padding: 8px;
	border-radius: 50%; /* Hace que el contenedor sea circular */
	cursor: pointer;
	z-index: 1; /* Asegúrate de que el contenedor esté por encima de otros elementos */
}

.edit-icon:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

@media (max-width: 550px) {
	.edit-icon {
		bottom: 5vh;
	}
}
