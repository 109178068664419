@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');

:root {

	--main-blue: #073EB8;
	--main-grey: #5C5C5C;

}



header {
	top: 0;
	width: 80vw;
	height: 8vh;
	min-height: 8vh;
	position: fixed;
	z-index: 2;
}

.mainHome {
	/* margin-top: 6vh; */
	min-height: 100vh;
	text-align: center;
	overflow-y: hidden;
	overflow-x: hidden;
	background-color: white;
	padding-bottom: 10vh;
}


.navAzul {

	width: 100%;
	height: 54px;
	background: var(--main-blue);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px 10%;
}
.sectionContact{
	padding: 100px;
	display: flex;
	background: #FFFFFF;
	background-size: cover;
	text-align: start;
	height: 110vh;
	justify-content: space-around;
}

.forms{
	padding: 11%;
	display: flex;
	background-color: '#FFF';
	background-size: cover;
	text-align: start;
	height: 110vh;
	justify-content: space-between;

}

.contactSection{

	width: 40%;
	display: flex;
	flex-direction: column;
	gap: 30px;
	align-items: flex-start;

}


.sectionForm{
	width: 50%
}

.navBlanca {

	width: 100%;
	height: 120px;
	background: white;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0px 10%;
	cursor:pointer

}

.dataNavAzul{
	font-family: "Josefin" !important;
	font-size: 16px !important;
	font-weight: 400 !important;
	color: white;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

}

.dataNavBlanca{

	font-family: "Josefin" !important;
	font-size: 16px !important;
	font-weight: 400 !important;
	color: var(--main-grey);
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

}

.BannerInicialFoto{

	width: 100%;
	background: white;
	display: flex;
	align-items: center;
	padding: 50px 10%; 

}

.drSection{

	background: white;
	width: 100%;
	padding: 250px 10%;
	display: flex;
	align-items: center;
	justify-content: space-between;

}

.clinicPhotos{

	background: transparent;
	width: 100%;
	padding: 50px 10%;
	display: flex;
	align-items: center;
	justify-content: space-between;

}

.clinicaSection{

	background: white;
	width: 100%;
	padding: 50px 10%;
	display: flex;
	align-items: center;
	justify-content: space-between;

}

.fotoDr{

	width: 40%;

}


.drDescription{

	width: 55%;
	display: flex;
	flex-direction: column;
	gap: 40px;

}

.drItems{

	font-size: 16px !important;
	color: var(--main-blue);
	font-family: "IvyOra" !important;

}

.drTitle{
  font-family: "IvyOra" !important;
	font-size: 42px !important;
	color: var(--main-blue);
	font-weight: 800;
	
}

.procedureTitle{

	font-family: "IvyOra" !important;
	font-size: 42px !important;
	color: var(--main-blue);
	font-weight: 800;
	width: 100%;
	text-align: center;
	margin-bottom: 200px;
	

}

.biography{

	font-family: "Josefin" !important;
	font-size: 20px !important;
	color: var(--main-blue);
	font-weight: 800;
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #073EB8;
	padding: 10px 100px;

}

.biographyText{

	font-family: "Josefin" !important;
	font-size: 20px !important;
	color: var(--main-blue);

}

.biographyText:hover{

	font-family: "Josefin" !important;
	font-size: 22px !important;
	color: var(--main-blue);
	cursor: pointer;

}

.biography:hover{

	cursor: pointer;

}

.treatments{

	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100vh;
	position: relative;
	z-index: 5;

}

.bigSquare{

	width: 50%;

	
}

.clinicaPhotoDesc{

	font-family: 'Josefin' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 22px !important;
	line-height: 28px !important;
	text-align: center !important;
	color: #FFFFFF !important;

}

.clinicaPhotoButton{

	font-family: 'Josefin' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 22px !important;
	line-height: 28px !important;
	color: #FFFFFF !important;
	border: 0.5px solid white;
	padding: 2.5%;
	display: flex;
	align-items: center;
	justify-content: center;

}

.clinicaPhotoButton:hover {

	font-family: 'Josefin' !important;
	font-style: normal !important;
	font-weight: 500 !important;
	font-size: 23px !important;
	line-height: 28px !important;
	color: #FFFFFF !important;
	border: 0.5px solid white;
	padding: 2.5%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

}

.fourSquares{

	width: 50%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

}

.smallSquare{
display: flex;
align-items: center;
justify-content: center;
	width: 50%;
	/* padding: 10% 10%; */
	display: flex;

}

.squareText{

	font-family: "IvyOra" !important;
	font-size: 32px !important;
	color: white;
	font-weight: 400 !important;
	font-style: italic !important;
}

.bigSquare{

	width: 50%;
	padding: 10% 10%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

}

.bigSquareText{


	font-family: "IvyOra" !important;
	font-size: 40px !important;
	color: white;
	font-weight: 200 !important;
	color: var(--main-blue);
	line-height: 100% !important;

}

.aboutContent1{

	display: flex;
	flex-direction: column;
	gap: 30px;

}

.aboutTitle{

	font-family: "IvyOra" !important;
	font-size: 42px !important;
	color: var(--main-grey);
	font-weight: 800;

}

.contactSubtext{

	font-family: "Josefin" !important;
	font-size: 16px !important;
	font-weight: 300 !important;
	color: var(--main-grey);
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	margin-bottom: 100px;

}



/* .fondoHome {
	background-image: url('../../assets/fondos/FONDO1.png');
} */

/* En tu archivo CSS */
.blur-top-edge {
  position: relative;
  overflow: hidden;
}

.blur-top-edge::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 300px; /* Controla la altura del área difuminada */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  pointer-events: none; /* Evita la interacción con el mouse */
}

.sectionCarousel {
	background-color: var(--color-claro);

}

.sectionAbout {
	background-color: var(--color-gris-trans);
	--mask: radial-gradient(56.6px at 50% calc(100% - 78px), #000 99%, #0000 101%) calc(50% - 60px) 0/120px
			100%,
		radial-gradient(56.6px at 50% calc(100% + 48px), #0000 99%, #000 101%) 50% calc(100% - 30px) /
			120px 100% repeat-x;
	-webkit-mask: var(--mask);
	mask: var(--mask);
}

.sectionAbout2 {
	background-color: var(--color-gris-trans);
	--mask: radial-gradient(56.6px at 50% 78px, #000 99%, #0000 101%) calc(50% - 60px) 0/120px 100%,
		radial-gradient(56.6px at 50% -48px, #0000 99%, #000 101%) 50% 30px/120px 100% repeat-x;
	-webkit-mask: var(--mask);
	mask: var(--mask);
}

.sectionVideo {
	margin-top: 5vh;
	margin-bottom: 5vh;
	display: flex;
	justify-content: center;
	padding: 20px;
	background-color: var(--color-claro);
	border: solid 10px var(--color-verde);
}

.divVideo {
	width: 40%;
	height: 90%;
}

.textContainer {
	width: 40%;

	display: flex;
	background-color: var(--color-claro);
	text-align: center;
	align-items: center;
	justify-content: center;
}
.mark {
	background-color: var(--color-claro);
	width: 60%;
}

.cotizacion {
	text-align: center;
	align-items: center;
	margin-top: 5vh;
	margin-bottom: 3vh;
	color: var(--color-claro);
	background-color: var(--color-verde);
	width: 20vw;

	border: none;
	height: 6vh;
}

.fondohome {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100px;
	height: 50px;
	position: absolute;
	bottom: 0;
	left: 5%;
	background-color: var(--color-verde);
	color: #ffffff;
	padding: 8px;
	border-radius: 10px;
	cursor: pointer;
	z-index: 1;
}
.edit-icon-video {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	margin: 2px 2px;
	background-color: var(--color-verde);
	color: var(--text-color);
	padding: 8px;
	border-radius: 50%;
	cursor: pointer;
	/* z-index: 1; */
}

.edit-icon-video:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}


@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

.menu-box {
  animation: slideIn 0.5s ease forwards;
}

.menu-box-exit {
  animation: slideOut 0.5s ease forwards;
}

.sectionScroll {
	width: 100vw;
	height: 15vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column-reverse;
}
.sectionScroll > img {
	width: 40px;
	animation: scrollAnimation 2s infinite; /* 2s representa la duración de la animación, puedes ajustarlo según tus preferencias */
	transition-timing-function: linear;
}
@keyframes scrollAnimation {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(10px); /* Ajusta la distancia que deseas para el movimiento vertical */
	}
	100% {
		transform: translateY(0);
	}
}

.fondohome:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}
@media (max-width: 750px) {

	.navBlanca {

		width: 100%;
		height: 120px;
		background: white;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		cursor:pointer;
		margin-top: 50px;
	
	}

	.BannerInicialFoto{

		width: 100%;
		background: white;
		display: flex;
		align-items: center;
		padding: 35px 5% 100px 5%; 
	
	}

	.sectionContact{
		padding-top: 100px;
		padding-bottom: 100px;
		padding-left: 11%;
		padding-right: 11%;
		display: flex;
		background: #ffffff;
		text-align: start;
		height: 100%;
		justify-content: space-around;
		flex-direction: column;
		gap: 100px;
	
	}
	.sectionForm{
		width: 100%;
	}

	.contactSection{

		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 30px;
	
	}
	.sectionCarousel {
		background-color: var(--color-claro);
		margin-top: -15%;
	}
	.cotizacion {
		margin-top: 7vh;
		width: 90vw;
	}

	.treatments{

		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100vh;
	
	}

	.drSection{

		background: white;
		width: 100%;
		padding: 30px 2%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		gap: 30px;
	
	}

	.fotoDr{

		width: 90%;
	
	}

	.drDescription{

		width: 90%;
		display: flex;
		flex-direction: column;
		gap: 30px;
	
	}

	.biography{

		font-family: "Josefin" !important;
		font-size: 20px !important;
		color: var(--main-blue);
		font-weight: 800;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #073EB8;
		padding: 10px 50px;
	
	}

	.clinicPhotos{

		background: transparent;
		width: 100%;
		padding: 50px 0%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-direction: column;
	
	}

	.fourSquares{

		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		height: 50%;
	
	}
	
	.smallSquare{
	
		width: 50%;
		height: 50%;
		padding: 10% 10%;
		display: flex;
	
	}
	
	.squareText{
	
		font-family: "IvyOra" !important;
		font-size: 20px !important;
		color: white;
		font-weight: 400 !important;
		font-style: italic !important;
	}
	
	.bigSquare{
	
		width: 100%;
		height: 50%;
		padding: 10% 10%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	
	}
	
	.bigSquareText{
	
	
		font-family: "IvyOra" !important;
		font-size: 32px !important;
		color: white;
		font-weight: 200 !important;
		color: var(--main-blue);
		line-height: 100% !important;
	
	}
	
	

}
@media (max-width: 450px) {
	.sectionVideo {
		flex-direction: column;
	}
	.divVideo {
		width: 100%;
	}
	.textContainer {
		width: 100%;
	}
	.cotizacion {
		width: 90vw;
	}
	.mainHome {
		margin-top: 0vh;
	}
	.sectionCarousel {
		top: -9vh;
		width: 100%;
		height: 33vh;
	}
	.fondohome {
		top: 20vh;
	}
}
