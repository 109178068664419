.containAbout {
	display: flex;
	justify-content: center;
}

.img1 > img {
	width: 80%;
	object-fit: contain;
	mask-repeat: no-repeat;
}

.edit-icon-about {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50px;
	height: 50px;
	position: absolute;
	top: 15%;
	right: 15%;
	background-color: var(--color-verde-claro);
	color: #ffffff;
	padding: 8px;
	border-radius: 50%;
	cursor: pointer;
	z-index: 1;
}

.edit-icon-about:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

.edit-icon-aboutText {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	margin: 2px 2px;
	background-color: var(--color-verde);
	color: var(--text-color);
	padding: 8px;
	border-radius: 50%;
	cursor: pointer;
	z-index: 1;
}

.edit-icon-aboutText:hover {
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

.guardarDesc {
	margin: 5px 0 5px 0;
	background-color: var(--color-verde);
	color: var(--text-color);
}

.guardarDesc:hover {
	color: var(--text-color);
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

.img1 {
	width: 50%;
	position: relative;
	transform: translateX(-100%);
	transition: transform 0.5s ease;
}

.text1 {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 5vw;
	width: 50%;
	transform: translateX(100%);
	transition: transform 0.5s ease;
}

.text1 > h5 {
	color: var(--color-oscuro);
	max-width: 75ch;
	text-align: center;
}

.text1 > p {
	color: var(--color-oscuro);
	max-width: 75ch;
	text-align: center;
}

.aboutLik {
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
}

.aboutLik > button {
	display: flex;
	align-items: center;
	padding: 8px 12px;
	border: none;
	border-radius: 10px;
	font-size: 1rem;
	background-color: var(--color-verde);
	color: var(--text-color);
	text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.aboutLik > button:hover {
	color: var(--text-color);
	background-color: var(--color-verde);
	box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
}

.animate-image {
	transform: translateX(0);
}
.animate-text {
	transform: translateX(0);
}

/* Estilos adicionales para el contenedor principal */
.container.containAbout {
	/* Asegura que el contenedor no ocupe más espacio vertical */
	height: auto;
	/* Agrega cualquier otro estilo necesario para el contenedor */
}

@media (max-width: 700px) {
	.containAbout {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.img1 {
		width: 100%;
	}
	.text1 {
		width: 100%;
		padding-bottom: 80px;
	}
}
