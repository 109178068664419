.flipping-card {

  perspective: 1000px;
  cursor: pointer;
  position: relative;
}

.flipping-card .front{
  
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    /* border-radius: 8px; */
    display: flex;
    align-items: 'flex-end';
    justify-content: 'flex-start';
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.6s;
  
}
.flipping-card .back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  /* border-radius: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.6s;
}

.flipping-card .front {

  color: white;
  
}

.flipping-card .back {
  background: #f5f5f5;
  transform: rotateY(180deg);
padding: 25px;
}


.textBack{

  font-family: "Josefin"!important;
	font-size: 20px !important;
  color: #5c5c5c !important;
	font-weight: 400 !important;
	font-style: italic !important;
}
.backMasto{


  font-family: "Josefin"!important;
  font-size: 14px !important;

}

.flipping-card.flipped .front {
  transform: rotateY(180deg);
}

.flipping-card.flipped .back {
  transform: rotateY(0);
}

@media (max-width: 750px) {

  .back{
    overflow: scroll !important;
  }
  .textBack{
	
    font-family: "Josefin"!important;
    font-size: 12px !important;

  }

  .backMasto{
    align-self: flex-start;
    overflow: scroll !important;
    font-family: "Josefin"!important;
    font-size: 12px !important;

  }
}

